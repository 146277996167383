/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import { getAPIVersion } from "api/api_version";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  const [apiVersion, setAPIVersion] = React.useState("");

  const fetchAPIVersion = async () => {
    try {
      const res = await getAPIVersion();
      setAPIVersion(res.version);
    } catch (error) {
      console.error("Error fetching backend version", error);
    }
  };

  React.useEffect(() => {
    fetchAPIVersion();
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid login-bg login-bg-img"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />

                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>

              <div
                className="text-dark order-2 order-md-1"
                style={{ position: "fixed", bottom: 15 }}
              >
                <a
                  href="http://stakecare.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark-75 text-hover-primary"
                >
                  StakeCare
                </a>{" "}
                &copy;{" "}
                <span
                  className="text-muted font-weight-bold mr-2"
                  title={`API Version: ${apiVersion}`}
                >
                  {" "}
                  - v{process.env.REACT_APP_VERSION}
                </span>
              </div>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
