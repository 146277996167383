import React from "react";
import { injectIntl } from "react-intl"
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"

export interface PRecordedAlertsColumnChartData {
  categories: Array<string>
  values: Array<number>
}
export interface PRecordedAlertsColumnChartProps {
  intl: any
  data?: PRecordedAlertsColumnChartData
  width?: number
  height?: number
  title?: string
  sortTitle?: string
}
function RecordedAlertsColumnChart(props: PRecordedAlertsColumnChartProps) {
  const { data, width, height, title, sortTitle } = props

  const [options, setOptions] = React.useState<ApexOptions>({
    chart: {
      id: 'apexchart-example',
      toolbar: {
        show: false,
        tools: {
          download: false
        }
      }
    },
    title: {
      text: title,
      align: 'left',
      style: {
        color: "white"
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    stroke:{
      colors:['#0000000']
     },
    colors:['#00E396D9', '#FEB019D9'],
    dataLabels: {

      offsetY: 0,
      style: {
        fontSize: '12px',
        colors: ["#2B244D"]
      }
    },
    xaxis: {
      categories: [],
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: "white"
        }
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return `${val}`;
        }
      }
    },
    responsive: [
      {
        breakpoint: 400,       
        options: {
          xaxis: {
            labels: {
              formatter: function (value: string) {
                // Faça a formatação do valor do label conforme necessário
                const index = data?.categories.indexOf(value);
                return typeof index === 'number' ? index % 3 === 0 ? value : '' : '';
              }
            }
          },
        }    
      },
      {
        breakpoint: 401,
        options: {
          xaxis: {
            labels: {
              formatter: function (value: string) {
                // Faça a formatação do valor do label conforme necessário
                return value;
              }
            }
          },
        }    
      },
      {
        breakpoint: 635,
        options: {
          title: {
            text: sortTitle !== undefined ? sortTitle : title,     
          }
        }    
      },
      {
        breakpoint: 636,
        options: {
          title: {
            text: title,     
          }
        }    
      },
      {
        breakpoint: 9999, // Valor alto para garantir que as opções sejam aplicadas em telas maiores que 781px
        options: {  
          xaxis: {
            labels: {
              formatter: function (value: string) {
                // Faça a formatação do valor do label conforme necessário
                return value;
              }
            }
          },
          title: {
            text: title,     
          }    
        },
      }
    ]
  })

  const [dataSerie, setDataSerie] = React.useState<Array<number>>([])
  
  React.useEffect(() => {
    if (!data) {
      setDataSerie([])
      return
    }

    const sanitizedValues = data.values.map((value) => (value != null && value !== undefined ? value : 0));

    setOptions({
      ...options,
      ...{
        xaxis: {
          categories: data?.categories
        }
      }
    })

    setDataSerie(sanitizedValues)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Chart
      type="bar"
      options={options}
      series={[{
        name: 'records',
        data: dataSerie
      }]}
      width={width ?? '100%'}
      height={height ?? '100%'}
      />
  )
}
export default injectIntl((RecordedAlertsColumnChart))
