import { NotificationCategories, NotificationSeverities } from "./notification"
import { PositionTypes } from "./position"

export interface IMonitoringReportEvent {
  timestamp   : number
  category    : NotificationCategories
  severity    : NotificationSeverities
  data        : any
}

export interface IMonitoringReportQuantitive {
  max : number
  min : number
  avg : number
}

export interface IMonitoringReportDataSet {
  ts_start    : number
  ts_end      : number

  events      : Array<IMonitoringReportEvent>

  positions   : Map<PositionTypes, IMonitoringPositionsSum>
  
  battery     : IMonitoringReportQuantitive
  temperature : IMonitoringReportQuantitive
  spo2        : IMonitoringReportQuantitive
  heartBeat   : IMonitoringReportQuantitive
}
export interface IMonitoringReportDataSetOutage {
  ts_start      : number
  ts_end        : number
}
export interface IMonitoringReport {
  monitoringId    : string
  ts_start        : number
  ts_end          : number

  dataset : Array<IMonitoringReportDataSet>
  outages : Array<IMonitoringReportDataSetOutage>
  events  : Array<IMonitoringReportEvent>
}
export interface IMonitoringPositionsSum {
  times       : number
  durationMs  : number
}

export class MonitoringReport {
  readonly monitoringId : string
  readonly ts_start     : number
  readonly ts_end       : number

  readonly dataset : Array<IMonitoringReportDataSet>
  readonly outages : Array<IMonitoringReportDataSetOutage>
  readonly events  : Array<IMonitoringReportEvent>

  readonly allEvents    : Array<IMonitoringReportEvent>
  readonly allPositions : Map<PositionTypes, IMonitoringPositionsSum>

  constructor(args: IMonitoringReport) {
    this.monitoringId   = args.monitoringId
    this.dataset        = args.dataset
    this.outages        = args.outages
    this.events         = args.events
    this.ts_start       = args.ts_start
    this.ts_end         = args.ts_end

    this.allEvents      = this.processEvents()
    this.allPositions   = this.processPositions()
  }

  private processEvents(): Array<IMonitoringReportEvent> {
    return this.dataset
      .reduce((arr: Array<IMonitoringReportEvent>, item: IMonitoringReportDataSet) => arr.concat(item.events), this.events)
      .sort((l, r) => l.timestamp - r.timestamp)
  }

  private processPositions(): Map<PositionTypes, IMonitoringPositionsSum> {
    return this.dataset
      .reduce((map: Map<PositionTypes, IMonitoringPositionsSum>, item: IMonitoringReportDataSet) => {
        new Map(Object.entries(item.positions)).forEach((value: IMonitoringPositionsSum, _key) => {
          let key = _key as PositionTypes
          let obj: IMonitoringPositionsSum = map.get(key) ?? {times: 0, durationMs: 0}

          obj.durationMs  += value.durationMs ?? 0
          obj.times       += value.times

          map.set(key, obj)
        })
        return map
      }, new Map())
  }
}
