
function getTenantConfig() {
  return require(`./${process.env.REACT_APP_TENANT}.json`)
}

export function getTenantStorageReduceKey() {
  return getTenantConfig().storageReduceKey
}
export function getTenantFirebaseConfigKey() {
  return getTenantConfig().firebaseConfig
}
