import React from "react";
import { injectIntl } from "react-intl"
import { BaseDistributedColumn } from ".";
import { getEpoch } from "_metronic/_helpers";

export interface PressureInjuryMonitoringsDistributedColumnData {
  enabled: number
  total: number
}
export interface PressureInjuryMonitoringsDistributedColumnProps {
  intl: any
  data?: PressureInjuryMonitoringsDistributedColumnData
  width?: number
  height?: number
}
function PressureInjuryMonitoringsDistributedColumn(props: PressureInjuryMonitoringsDistributedColumnProps) {
  const { intl, data, width, height } = props
  const [newSignal, setNewSignal] = React.useState<number>(0)
  
  const [labels] = React.useState<Array<string>>([
    intl.formatMessage({ id: "REPORTS.PRESSURE_INJURY.ENABLED" }),
    intl.formatMessage({ id: "REPORTS.PRESSURE_INJURY.DISABLED" })
  ])
  const [colors] = React.useState<Array<string>>([
    '#19fe40',
    '#fe1919'
  ])

  const onDataSerie = () => {
    if (!data) return null

    return [ data.enabled, data.total - data.enabled ]
  }

  React.useEffect(() => {
    setNewSignal(getEpoch())
  }, [data])

  return (
    <BaseDistributedColumn
      width={width ?? '100%'} 
      height={height ?? '100%'}
      id='pressure-injuries-monitorings'
      title={intl.formatMessage({id: "REPORTS.PRESSURE_INJURY.TITLE"})}
      modalTitleId={"REPORTS.PRESSURE_INJURY.TITLE"}
      modalBodyTextId={"REPORTS.PRESSURE_INJURY.DETAILS"}
      colors={colors}
      labels={labels}
      onDataSerie={onDataSerie}
      newDataSignal={newSignal}
      />
  )
}
export default injectIntl((PressureInjuryMonitoringsDistributedColumn))
