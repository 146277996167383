import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import HomePage from "app/modules/Home/HomePage";
import { useSelector } from "react-redux";
import { ADMIN_ROLE } from "app/models/user";
import { isProduction } from "_metronic/_helpers"

import {
  homeBasePath,
  monitoringPath,
  overviewReportsPath,
  dashboardReportsPath,
  indicatorsPath,
  devicesPath,
  pacientsPath,
  medicinesPath,
  sectorsPath,
  usersPath,
  wifisPath
} from "api/endpoints"

const MonitoringRoute = lazy(() =>
  import("app/modules/Monitoring/MonitoringRoute")
);
const ReportsRoute = lazy(() =>
  import("app/modules/Reports/Overview/OverviewPage"),

);
const DashboardRoute = lazy(() =>
    import("app/modules/Reports/Dashboard/DashboardPage")
);
const IndicatorsRoute = lazy(() =>
    import("app/modules/Reports/Indicators/IndicatorsRoute")
);

const PacientsRoute = lazy(() =>
  import("app/modules/Management/Pacients/PacientsRoute")
);
const MedicinesRoute = lazy(() =>
  import("app/modules/Management/Medicines/MedicinesRoute")
);
const DevicesRoute = lazy(() =>
  import("app/modules/Management/Devices/DevicesRoute")
);
const SectorsRoute = lazy(() =>
  import("app/modules/Management/Sectors/SectorsRoute")
);
const WiFisRoute = lazy(() =>
  import("app/modules/Management/WiFis/WiFisRoute")
);
const UsersRoute = lazy(() =>
  import("app/modules/Management/Users/UsersRoute")
);

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);

export default function BasePage() {
  const errorItems = ["v1", "v2", "v5"];
  const errorVersion = errorItems[Math.floor(Math.random()*errorItems.length)];
  const { user } = useSelector((state) => { return state.auth });

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={homeBasePath} />
        }

        <Route path={homeBasePath}        component={HomePage} />
        
        <Route path={monitoringPath}      component={MonitoringRoute} />
        {user.role === ADMIN_ROLE && (
          <Route path={overviewReportsPath} component={ReportsRoute} />
        )}
        <Route path={dashboardReportsPath} component={DashboardRoute} />
        <Route path={indicatorsPath}      component={IndicatorsRoute} />
        <Route path={pacientsPath}        component={PacientsRoute} />
        <Route path={medicinesPath}       component={MedicinesRoute} />
        <Route path={devicesPath}         component={DevicesRoute} />
        <Route path={sectorsPath}         component={SectorsRoute} />
        <Route path={wifisPath}           component={WiFisRoute} />
        <Route path={usersPath}           component={UsersRoute} />

        
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/google-material"  component={GoogleMaterialPage} />
        <Route path="/react-bootstrap"  component={ReactBootstrapPage} />

        {
          !isProduction() && (
            <>
              <Route path="/google-material" component={GoogleMaterialPage} />
              <Route path="/react-bootstrap" component={ReactBootstrapPage} />
            </>
          )
        }
        
        <Redirect to={`/error/error-${errorVersion}`} />
      </Switch>
    </Suspense>
  );
}
