import React from "react"
import { injectIntl } from "react-intl"
import { getHomeCharts } from "api/home"
import { PRecordedAlertsColumnChartData } from "../Charts/RecordedAlertsColumnChart"
import { times as _times } from "lodash"
import { IApiQueryDataCharts} from "app/models/monitoring"
import './styles-home.scss'

function HomePage(props: any) {
  const [recordedAlerts, setRecordedAlerts] = React.useState<PRecordedAlertsColumnChartData>()
  const [displayRecordedAlerts, setDisplayRecordedAlerts] = React.useState('block')

  React.useEffect(() => {
    const cachedData = localStorage.getItem("homeChartsData");
  
    const fetchData = async () => {
      try {
        const res = await getHomeCharts();
        updateChartsData(res);
        localStorage.setItem("homeChartsData", JSON.stringify(res));
      } catch (error) {
        console.error("Error fetching home charts data:", error);
      }
    };
  
    if (cachedData) {
      const parsedData: IApiQueryDataCharts = JSON.parse(cachedData);
      updateChartsData(parsedData);
    } else {
      fetchData();
    }
  
    // Add an event listener to handle page reload
    const handlePageReload = () => {
      localStorage.removeItem("homeChartsData");
    };
  
    window.addEventListener("beforeunload", handlePageReload);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if(recordedAlerts !== undefined && hasNoValues(recordedAlerts)){
      setDisplayRecordedAlerts('none')
    }
    else {
      setDisplayRecordedAlerts('block')
    }
  }, [recordedAlerts])

  function updateChartsData(data: IApiQueryDataCharts) {
    setRecordedAlerts(sortRecordedAlerts(data.alerts));
  } 

  function sortRecordedAlerts(alerts: any): PRecordedAlertsColumnChartData {
    const cHour = (new Date()).getHours()
    const order: Array<string> = []
    const values: Array<number> = []

    if (!!Object.keys(alerts).length)
      _times(12, (idx: number) => {
        const vIndex = cHour - idx
        if (vIndex >= 0) {
          order.unshift(`${vIndex}`)
          values.unshift(alerts[vIndex])
        } else {
          const backwardIndex = 24 - Math.abs(vIndex)
          order.unshift(`${backwardIndex}`)
          values.unshift(alerts[backwardIndex])
        }
      })

    return {
      categories: order.map((v) => `${v.padStart(2, "0")}:00`),
      values: values
    }
  }

  function hasNoValues(object: PRecordedAlertsColumnChartData): boolean {
    return object.values.every((element: number | string) => element === 0 || element === undefined)
  }

  return (
    <div className="charts_container">
      <img src="/media/bg/marcadagua.jpeg" alt={"logo"}/>
    </div>
  )
}
export default injectIntl((HomePage))
