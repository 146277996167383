import { BaseModel } from "./base";

export const ADMIN_ROLE = "admin"
export const USER_ROLE = "user"
export type UserRole = typeof ADMIN_ROLE | typeof USER_ROLE

export interface IUser {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  blockedAt: number | null;
  role?: UserRole;
  isDeletable: boolean;
}

export interface IUserUpdate {
  firstname: string;
  lastname: string;
}

export interface IUserBlocked {
  blockedAt: number | null;
}

export class User extends BaseModel
  implements IUser, IUserUpdate, IUserBlocked {
  readonly id: string;
  readonly email: string;
  readonly firstname: string;
  readonly lastname: string;
  readonly role: UserRole;
  readonly blockedAt: number | null;
  readonly isDeletable: boolean = false;

  constructor(args: IUser) {
    super();

    this.id = args.id;
    this.email = args.email;
    this.firstname = args.firstname;
    this.lastname = args.lastname;
    this.role = args.role ?? "user";
    this.blockedAt = args.blockedAt;
    this.isDeletable = args.isDeletable ?? false;
  }
}
