import { IMonitoringBradenScale } from "app/models/monitoring"

export const BradenScales = {
  EMPTY     : "EMPTY",
  VERY_HIGH : "VERY_HIGH",
  HIGH      : "HIGH",
  MEDIUM    : "MEDIUM",
  LOW       : "LOW"
}
export function bradenScaleRangeStyles(_scalePoints: number | string) {
  let scalePoints = _scalePoints !== "" ? _scalePoints : 0

  switch(true) {
    case (scalePoints === 0): 
      return {
        variant: "info",
        color: "text-info",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.EMPTY",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.EMPTY.SHORT",
        category: "EMPTY",
        index: 0
      }
    case (scalePoints <= 9): 
      return {
        variant: "danger",
        color: "text-danger",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.VERY_HIGH",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.VERY_HIGH.SHORT",
        category: "VERY_HIGH",
        index: 5
      }
    case (scalePoints <= 12): 
      return {
        variant: "danger",
        color: "text-danger",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.HIGH",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.HIGH.SHORT",
        category: "HIGH",
        index: 4
      }
    case (scalePoints <= 14): 
      return {
        variant: "warning",
        color: "text-warning",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.MEDIUM",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.MEDIUM.SHORT",
        category: "MEDIUM",
        index: 3
      }
    case (scalePoints <= 18): 
      return {
        variant: "primary",
        color: "text-primary",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.LOW",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.LOW.SHORT",
        category: "LOW",
        index: 2
      }
    default: 
      return {
        variant: "success",
        color: "text-success",
        rangeTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.VERY_LOW",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.BRADEN_SCALE.RANGE.VERY_LOW.SHORT",
        category: "VERY_LOW",
        index: 1
      }
  }
}

export function calculateScaleScore(scale: IMonitoringBradenScale): number {
  const { detailed } = scale ?? {}

  if (detailed)
    return Object.values(detailed).reduce((partial, total) => partial + total, 0)
  else
    return 0
}
