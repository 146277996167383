import React from "react";
import { get as _get } from "lodash"
import { NewFormTextField } from "app/modules/Globals";
import { convertStringBrDateToAmerican, getAge, isValidDate } from "_metronic/_helpers";

export const FormBirthdayField = (props: any) => {
  const { displayAge, values, valueRef, errors, touched } = props
  
  const value = _get(values, valueRef) ?? ""  

  const [day, month, year] = value.split("/")

  const inputDate = new Date(year, month -1, day)

  const invalidDate = inputDate > new Date()

  if(invalidDate && errors[valueRef] === undefined)
    errors[valueRef] = 'Data inválida'

  const appendText = (displayAge && value?.length === 10 && isValidDate(value) && !invalidDate) ? `${ getAge(new Date(convertStringBrDateToAmerican(value))) } anos` : undefined 

  return (
    <>
      <NewFormTextField 
        {...props} 
        withMask="99/99/9999" 
        withAppendText={appendText} 
        errors={errors}
        touched={touched}
        />
    </>
  )
}
