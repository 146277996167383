import React from "react";
import {useLang} from "./Metronici18n";
import {IntlProvider} from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/pt";

import ptMessages from "./messages/pt_br";
import ptILPSMessages from "./messages/pt_brILPs";
import ptIgrejaMessages from "./messages/pt_brIgreja";
import ptAbrigoMessages from "./messages/pt_brAbrigo";
import enMessages from "./messages/en";

let localeLang = process.env.REACT_APP_LOCALE || 'pt_br'; // Default to 'pt_br' if the environment variable is not set
let ptValue;

if(localeLang === 'pt_brILPs') {
  ptValue = ptILPSMessages;
}
else if(localeLang === 'pt_brIgreja') {
  ptValue = ptIgrejaMessages;
}
else if(localeLang === 'pt_brAbrigo') {
  ptValue = ptAbrigoMessages;
}
else {
  ptValue = ptMessages;
}

const allMessages = {
  pt: ptValue,
  en: enMessages
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
