import React from "react";
import { injectIntl } from "react-intl"
import { BaseDonut } from ".";
import { getEpoch } from "_metronic/_helpers";

export interface PCurrentDevicesInUse {
  inUse: number
  total: number
}
export interface PCurrentDevicesInUseProps {
  intl: any
  data?: PCurrentDevicesInUse
  width?: number
  height?: number
}
function CurrentDevicesInUseDonut(props: PCurrentDevicesInUseProps) {
  const { data, width, height } = props
  const [newSignal, setNewSignal] = React.useState<number>(0)

  const onDataSerie = () => {
    if (!data) return null
    return [data.inUse, (data.total - data.inUse)]
  }

  React.useEffect(() => {
    setNewSignal(getEpoch())
  }, [data])

  return (
    <BaseDonut
      width={width ?? '100%'} 
      height={height ?? '100%'}
      id='devices-in-use'
      title="Dispositivos em Uso"
      colors={['#00E396D9', '#FEB019D9']}
      labels={['Em Uso', 'Ocioso']}
      onDataSerie={onDataSerie}
      newDataSignal={newSignal}
      /> 
  )
}
export default injectIntl((CurrentDevicesInUseDonut))
