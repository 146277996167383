import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "_metronic/_helpers";
import {
    Image
} from "react-bootstrap";
import React, { useState } from "react";

export function CompanyHeader() {
    const [logoUrl] = useState( process.env.REACT_APP_LOGO ? toAbsoluteUrl(`/media/logos/${process.env.REACT_APP_LOGO}`) : null )
    const appLogoHeight = process.env.REACT_APP_LOGO_HEIGHT_HEADER ?? 40

    return(
    <>
      <div className="header-menu-wrapper header-menu-wrapper-center" id="kt_header_menu_wrapper">
        <div className="header-logo" style={{display:'flex', height:'100%', alignItems:'center'}}>
          <Link to="/" title="StakeCare">
            {
            (logoUrl) && <Image src={logoUrl} style={{ height: appLogoHeight }}/>
            }
          </Link>
        </div>
      </div>
    </>
  )
}
