//
//
export const notFoundPath         = "/error/error-not-found"
export const homeBasePath         = "/home"

//
export const monitoringPath             = "/monitoring"
export const monitoringSectorPath       = `${monitoringPath}/sector/:id`
export const monitoringLPPDashboardPath = `${monitoringPath}/sector/:id/lpp-dashboard`

export const overviewReportsPath   = "/reports/overview"
export const dashboardReportsPath  = "/reports/dashboard"

export const pacientsPath         = "/pacients"
export const medicinesPath        = "/medicines"
export const devicesPath          = "/devices"
export const sectorsPath          = "/sectors"
export const wifisPath            = "/wifis"
export const usersPath            = "/users"
export const indicatorsPath       = "/indicators"

//
//
export const userProfileBasePath  = "/user-profile"
