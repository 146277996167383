import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Col,
  Form,
  InputGroup
} from "react-bootstrap";
import { get as _get } from "lodash"

export const FormTextFieldAppended = (props) => {
  const { intl, required, disabled, xs, lg, className, titleId, appendText, commentId, valueRef, overrideValue, values, type, errors, touched, handleChange, handleBlur } = props
  const isInvalid = (touched && errors) && Boolean(touched[valueRef] && errors[valueRef])
  const isValid   = (touched && errors) && Boolean(touched[valueRef] && !errors[valueRef])

  console.log(intl, titleId)
  return (
    <Form.Group as={Col} xs={xs ?? 12} lg={lg ?? 4}>
      <Form.Label><FormattedMessage id={titleId} /></Form.Label>
        <InputGroup>
          <Form.Control
            required={required ?? false}
            disabled={disabled ?? false}
            key={valueRef}
            id={valueRef}
            name={valueRef}
            type={type ?? "text"}
            placeholder={intl.formatMessage({id: titleId})}
            value={overrideValue ?? _get(values, valueRef) ?? ""}
            isInvalid={isInvalid ?? false}
            isValid={isValid ?? false}
            onChange={handleChange}
            onBlur={handleBlur}
            className={className}
            />
          { (commentId) && <Form.Label  style={{color:'#999', fontSize:11}}><FormattedMessage id={commentId} /></Form.Label> }
          <InputGroup.Append>
            <InputGroup.Text style={{minWidth:50, textAlign:"right"}}>{ appendText }</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      <Form.Control.Feedback type="invalid">{ errors && errors[valueRef]}</Form.Control.Feedback>
    </Form.Group>
  )
}
