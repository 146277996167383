/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef, useImperativeHandle } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import { Spinner } from "react-bootstrap";
import {
  Card,
  CardBody
} from "_metronic/_partials/controls";
import { logToSentry } from "_metronic/_helpers/SentryHelper";

export const LoadingComponent = forwardRef((props, ref) => {
  const { 
    loadingMessage,
    noLoadingMessage,
    noItemsMessage,
    noItemsIcon,
    startLoading,
    displayInCard : _displayInCard
  } = props

  const [isLoading, setIsLoading]           = React.useState(startLoading ?? false);
  const [hasError, setHasError]             = React.useState(false);
  const [noItems, setNoItems]               = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState(loadingMessage ?? "LOADING.TEXT")
  const [displayInCard]                     = React.useState(_displayInCard ?? false)

  useImperativeHandle(ref, () => ({
    isLoading: () => { return isLoading },
    start: () => {
      setDisplayMessage(loadingMessage  ?? "LOADING.TEXT")
      setIsLoading(true)
      setHasError(false)
    },
    stop: (withNoItems = false) => {
      setIsLoading(false)
      setNoItems(withNoItems)
      setDisplayMessage(withNoItems ? noItemsMessage : null)
    },
    stopWithError: (error, errorMessage = "LOADING.ERROR") => {
      logToSentry(error, 'stopWithError')
      
      setIsLoading(false)
      setHasError(true)
      
      if (error?.code === "internal")
        errorMessage = "CODE.INTERNAL"
        
      setDisplayMessage(errorMessage ?? "LOADING.ERROR")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [loadingMessage, noItemsMessage]);

  const SpinnerView = () => {
    if (!isLoading) return null

    return (
      <Spinner animation="grow" variant="primary" />
    )
  }
  const IconView = () => {
    if (isLoading || (!hasError && !noItems)) return null

    return (
      <span className={`svg-icon svg-icon-3x ${hasError ? "svg-icon-danger" : "svg-icon-primary"}`}>
        <SVG
          src={toAbsoluteUrl(
            hasError ? "/media/svg/icons/Code/Warning-2.svg" : (noItemsIcon ?? "/media/svg/icons/General/Search.svg")
          )}
          title="Visualizar"
        ></SVG>
      </span>
    )
  }
  const MessageView = () => {
    if (isLoading && noLoadingMessage) return null

    return (
      <div className={`mt-4 ${hasError && "text-danger"}`}>
        <FormattedMessage id={`${displayMessage ?? "LOADING.TEXT"}`} />
      </div>
    )
  }

  const LoadingView = () => {
    return (
      <div className="row" style={{textAlign:'center', minHeight:100}}>
        <div className="col-12" style={{margin:'auto'}}>
          <SpinnerView />
          <IconView />
          <MessageView />
        </div>
      </div>
    )
  }
  const LoadingViewWithCard = () => {
    return (
      <Card>
        <CardBody>
          <LoadingView />
        </CardBody>
      </Card>
    )
  }

  return (
    <>
      {
        (isLoading || noItems || hasError) && (
          displayInCard ? <LoadingViewWithCard /> : <LoadingView />
        )
      }
    </>
  );
})
