import * as React from "react";
import { getLatestNotifications, startListeningNotifications, stopListeningNotifications } from "api/notifications"
import { Notification } from "app/models/notification" 
import { getEpoch } from "_metronic/_helpers";

const Context = React.createContext<{ 
  allNotifications: Array<Notification> | undefined
  arrivedNotifications: Array<Notification>
  isLoaded: () => boolean
}>({ allNotifications: [], arrivedNotifications: [], isLoaded: () => false })

export default function Provider({ children }: any) {
  const [allNotifications, setAllNotifications] = React.useState<Array<Notification> | undefined>(undefined)
  const [arrivedNotifications, setArrivedNotifications] = React.useState<Array<Notification>>([])

  function isLoaded() { 
    return allNotifications !== undefined
  }

  React.useEffect(() => {
    getLatestNotifications((latestNotifications) => {
      setAllNotifications(latestNotifications.reverse())

      startListeningNotifications(getEpoch(), (arrivedNotifications) => {
        if (arrivedNotifications.length === 0) return

        setArrivedNotifications(arrivedNotifications)
      })
    })
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (isLoaded() && allNotifications) {
      setAllNotifications([...arrivedNotifications, ...allNotifications])
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivedNotifications])
  React.useEffect(() => {
    return () => stopListeningNotifications()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Context.Provider
      value={{
        isLoaded: isLoaded,
        allNotifications: allNotifications,
        arrivedNotifications: arrivedNotifications
      }}
      >
        {children}
    </Context.Provider>
  );
}

Provider.Context = Context;
