import React from "react";
import DynamicDecubitusClockTable from "./DynamicDecubitusClockTable";

// Props
export interface FixedDecubitusClockTableProps {
  rowsLimit?: number;
  showSelectInitialInterval?: boolean;
  highlightCurrentInterval?: boolean;
  currentIntervalFirst?: boolean;
  fullWidth?: boolean;
}

// Component
function FixedDecubitusClockTable({
  rowsLimit = 99,
  showSelectInitialInterval = false,
  highlightCurrentInterval = true,
  currentIntervalFirst = false,
  fullWidth = false,
}: FixedDecubitusClockTableProps) {
  // Create a mocked startMonitoringDate with the current date at 00:00:00
  // This will cause the dynamic clock to look exactly like the original one
  const startMonitoringDate = new Date();
  startMonitoringDate.setHours(0, 0, 0, 0);

  return (
    <DynamicDecubitusClockTable
      startMonitoringDate={startMonitoringDate}
      hoursInterval={2}
      rowsLimit={rowsLimit}
      showSelectInitialInterval={showSelectInitialInterval}
      highlightCurrentInterval={highlightCurrentInterval}
      currentIntervalFirst={currentIntervalFirst}
      fullWidth={fullWidth}
    />
  );
}

export default FixedDecubitusClockTable;
