/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { MenuItem } from "."
import { 
  overviewReportsPath,
  dashboardReportsPath,
  indicatorsPath,
} from "api/endpoints"
import { ADMIN_ROLE } from "app/models/user";
import { FormattedMessage, injectIntl } from "react-intl";

function ReportSection(props) {
  const { intl, user } = props
  const cProps = {
    intl: intl,
    display: true,
    parentTitleId: "MENU.REPORTS"
  }

  return (
      <>
        <li className="menu-section ">
          <h4 className="menu-text"><FormattedMessage id="MENU.REPORTS" /></h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {user.role === ADMIN_ROLE && (
          <MenuItem
            {...cProps}
            titleId={"MENU.REPORTS.OVERVIEW"}
            url={overviewReportsPath}
            icon="/media/svg/icons/Shopping/Chart-bar1.svg"
          />
        )}
        <MenuItem
          {...cProps}
          titleId={"MENU.REPORTS.DASHBOARD"}
          url={dashboardReportsPath}
          icon="/media/svg/icons/Shopping/Chart-bar2.svg"
          />
        {user.role === ADMIN_ROLE && (
          <MenuItem
            {...cProps}
            titleId={"MENU.REPORTS.REGISTER.INDICATORS"}
            url={indicatorsPath}
            icon="/media/svg/icons/Communication/Clipboard-list.svg"
          />
        )}
      </>
  );
}

export default injectIntl(ReportSection)
