import * as React from "react";
import { connect } from "react-redux";
import * as auth from "app/modules/Auth/_redux/authRedux";
import { useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { getUserByToken } from "api/authenticate";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { getDiffInMinutes, getEpoch } from "_metronic/_helpers";
import { logToSentry, setSentryUser } from "_metronic/_helpers/SentryHelper";

const Context = React.createContext<{ 
  isAuthenticated: () => boolean | undefined
}>({ isAuthenticated: () => false })

function Provider(props: any) {  
  const { children } = props
  const { lastSync } = useSelector((state: any) => state.auth);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = React.useState<string>("")

  React.useEffect(() => {
    const sinceLastSync = getDiffInMinutes(lastSync, getEpoch())

    // Redo userByToken if lastSync older than 1h
    if (sinceLastSync > 60) {
      console.debug("Updating.. lastSync =>", lastSync, getDiffInMinutes(lastSync, getEpoch()))
      getUserByToken()
        .then(({ user, lastSync }) => {
          setIsAuthenticated(true)

          setSentryUser(user)

          props.refreshLasySync(lastSync)
        })
        .catch((err) => {
          let errMsg = "AUTH.NOT_AUTHENTICATED_MODAL.UNKNOWN_ERROR"

          if (err.code === "unauthenticated")
            errMsg = "AUTH.NOT_AUTHENTICATED_MODAL.MESSAGE"
          else if (err.code === '403')
            errMsg = "AUTH.NOT_AUTHENTICATED_MODAL.MESSAGE_ERROR"
          else 
            logToSentry(err, 'getUserByToken while refreshing token failed')
          
          setErrorMessage(errMsg)
          setIsAuthenticated(false)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Context.Provider
      value={{ 
        isAuthenticated: () => isAuthenticated
      }}
      >
        {
          (isAuthenticated === false) && (
            <Modal show={true} onHide={() => {}}>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage id="AUTH.NOT_AUTHENTICATED_MODAL.TITLE" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormattedMessage id={errorMessage} />
              </Modal.Body>
              <Modal.Footer style={{ justifyContent: 'center' }}>
                <Link to="/logout">
                  <Button variant="primary">
                    <FormattedMessage id="AUTH.NOT_AUTHENTICATED_MODAL.OK" />
                  </Button>
                </Link>
              </Modal.Footer>
            </Modal>
          )
        }
        {children}
    </Context.Provider>
  );
}
export default injectIntl(connect(null, auth.actions)(Provider));

Provider.Context = Context;
