import React, { useEffect, useState } from 'react'
import { injectIntl, WrappedComponentProps  } from 'react-intl'
import IPropsPatientWarningsItem from '../interfaces/IPropsPatientWarningsItem'
import { Button, Modal } from 'react-bootstrap'
import TablePatientWarningsMedicines from './TablePatientWarningsMedicines'

function PatientWarningsMedicinesItem(props: IPropsPatientWarningsItem & WrappedComponentProps) {
  const { listItem, titleSpan, contentSpan }  = props

  const [showModalDetails, setShowModalDetails] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
        onClose();
    };

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function onClose() {
    setShowModalDetails(false)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <>
      <div className="d-flex cursor-pointer bg-hover-primary py-4 px-4" onClick={() => setShowModalDetails(true)}>
        <div className={`symbol symbol-40 symbol-light-warning mr-5`}>
          <span className="symbol-label">
            <div className="navi-icon">
              <i className={`flaticon2-warning icon-lg text-warning`} />
            </div>
          </span>
        </div>
        <div className="d-flex flex-column font-weight-bold text-hover-white" style={{flexGrow: 1}}>
          <span className={`text-warning mb-1 font-size-lg`}>
            { titleSpan }
          </span>
          <span className="text-black">
            { contentSpan }
          </span>
        </div>
      </div>

      {showModalDetails && (
        <Modal centered show={true} onHide={handleClose} size="lg" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{ titleSpan }</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <TablePatientWarningsMedicines
              rows={listItem}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>  
  )
}

export default injectIntl((PatientWarningsMedicinesItem))

