export type PositionTypeString = 'DDH_DDE' | 'DDE' | 'DDE_SED' | 'SED' | 'DDH' | 'DLE' | 'DLD' | 'DV' | 'DLE_DV' | 'DDH_DLE' | 'DDH_DLD' | 'DLD_DV' | 'FALL_RAISED' | 'UPSIDE_DOWN' | 'UNK' | 'INV'

export const PositionType = {
  DDH_DDE: 'DDH_DDE',
  DDE : 'DDE',
  DDE_SED: 'DDE_SED',
  SED : 'SED',

  DDH : 'DDH',
  DLE : 'DLE',
  DLD : 'DLD',
  DV  : 'DV',

  DLE_DV: 'DLE_DV',
  DDH_DLE: 'DDH_DLD',
  DDH_DLD: 'DDH_DLD',
  DLD_DV: 'DLD_DV',

  FALL_RAISED: 'FALL_RAISED', UPSIDE_DOWN: 'UPSIDE_DOWN',
  UNK : 'UNK', INV : 'INV'
} as const

export type PositionTypes = typeof PositionType[keyof typeof PositionType]

export interface PositionMatcher {
  id: PositionTypes
  isMatch: (x: number, y: number) => boolean,
  linked?: Array<PositionTypes>
}

// Function to determine the color of a decubitus position
export function getDecubitusPositionColor(position: PositionTypeString): string {
  switch (position) {
    case PositionType.DDH:
      return "#EEF1EB";
    case PositionType.DLE:
      return "#778C61";
    case PositionType.DLD:
      return "#CEE6C5";
    default:
      return "transparent";
  }
}

// Function to determine, given the current date, what is the decubitus position in the FIXED clock approach
export const getFixedClockDecubitusPositionForDate = (
  currentDate: Date,
  hoursInterval: number = 2
): PositionTypeString => {
  // The order of positions
  const positions: PositionTypeString[] = [
    PositionType.DLD, PositionType.DDH, PositionType.DLE,
    PositionType.DLD, PositionType.DDH, PositionType.DLE
  ];

  // Calculate the total number of intervals in a 24-hour day
  const intervalsPerDay = 24 / hoursInterval;

  // Calculate the current interval index
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const totalMinutes = hours * 60 + minutes;
  const intervalIndex =
    Math.floor(totalMinutes / (hoursInterval * 60)) % intervalsPerDay;

  // Determine the position based on the interval index
  return positions[intervalIndex % positions.length];
};

// Function to determine, given a start date and the current date, what is the decubitus position in the DYNAMIC clock approach
export const getDynamicClockDecubitusPositionForDate = (
  startDate: Date,
  currentDate: Date,
  hoursInterval: number = 2
): PositionTypeString => {
  const positionInFixedClock = getFixedClockDecubitusPositionForDate(startDate, hoursInterval);

  let positions: PositionTypeString[] = [];
  if (positionInFixedClock === PositionType.DDH) {
    positions = [
      PositionType.DDH, PositionType.DLE, PositionType.DLD,
    ];
  } else if (positionInFixedClock === PositionType.DLD) {
    positions = [
      PositionType.DLD, PositionType.DDH, PositionType.DLE,
    ];
  } else if (positionInFixedClock === PositionType.DLE) {
    positions = [
      PositionType.DLE, PositionType.DLD, PositionType.DDH,
    ];
  }

  // We know that the patient started their clock at the position "positionInFixedClock"
  // The patient will do "hoursInterval" hours in that position, then move to the next position

  // Now, given the current date, we need to determine in which position the patient is
  // For that, for each "hoursInterval" hours passed, we will move to the next position

  const timeDifference = currentDate.getTime() - startDate.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  const intervalsPassed = Math.floor(hoursDifference / hoursInterval);
  const positionIndex = intervalsPassed % positions.length;

  return positions[positionIndex];
};
