export const idsAndPoints = {
  case        : {
    name: 'case',
    ids: {
      "1"   : { points: '1' },
      "2"   : { points: "13" },
      "3"   : { points: "14" },
      "4"   : { points: "15" },
      "NA"  : { points: "0" }
    }
  },
  age         : {
    name: 'age',
    ids: {
      "1"   : { points: '1' },
      "2"   : { points: "2" },
      "3"   : { points: "3" }
    }
  },
  history     : {
    name: 'history',
    ids: {
      "1"   : { points: '5' },
      "NA"  : { points: "0" }
    }
  },
  evacuate    : {
    name: 'evacuate',
    ids: {
      "1"   : { points: '2' },
      "2"   : { points: '2' },
      "3"   : { points: '4' }
    }
  },
  medicines   : {
    name: 'medicines',
    ids: {
      "1"   : { points: '3' },
      "2"   : { points: '5' },
      "3"   : { points: '7' }
    }
  },
  equipments  : {
    name: 'equipments',
    ids: {
      "1"   : { points: '1' },
      "2"   : { points: '2' },
      "3"   : { points: '3' }
    }
  },
  mobility    : {
    name: 'mobility',
    ids: {
      "help"    : { points: '2' },
      "walk"    : { points: '2' },
      "visual"  : { points: '2' }
    }
  },
  cognition   : {
    name: 'cognition',
    ids: {
      "perception"   : { points: '1' },
      "impulsivity"  : { points: '2' },
      "limitations"  : { points: '3' }
    }
  }
}

export const scales = {
  EMPTY     : "EMPTY",
  HIGH      : "HIGH",
  MEDIUM    : "MEDIUM",
  LOW       : "LOW"
}
export function scaleRangeStyles(_scalePoints: number | string | null | undefined) {
  let scalePoints = parseInt(`${_scalePoints}`)
  
  if (isNaN(scalePoints))
    return {
      variant: "info",
      color: "text-info",
      rangeTextId: "MONITORING.DETAIL_DIALOG.JOHNS_HOPKINS_SCALE.RANGE.EMPTY",
      rangeShortTextId: "MONITORING.DETAIL_DIALOG.JOHNS_HOPKINS_SCALE.RANGE.EMPTY.SHORT",
      category: scales.EMPTY,
      index: 0
    }

  switch(true) {      
    case (scalePoints <= 5):
      return {
        variant: "success",
        color: "text-success",
        rangeTextId: "MONITORING.DETAIL_DIALOG.JOHNS_HOPKINS_SCALE.RANGE.LOW",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.JOHNS_HOPKINS_SCALE.RANGE.LOW.SHORT",
        category: scales.LOW,
        index: 3
      }
    case (scalePoints <= 13): 
      return {
        variant: "warning",
        color: "text-warning",
        rangeTextId: "MONITORING.DETAIL_DIALOG.JOHNS_HOPKINS_SCALE.RANGE.MEDIUM",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.JOHNS_HOPKINS_SCALE.RANGE.MEDIUM.SHORT",
        category: scales.MEDIUM,
        index: 2
      }
    default: 
      return {
        variant: "danger",
        color: "text-danger",
        rangeTextId: "MONITORING.DETAIL_DIALOG.JOHNS_HOPKINS_SCALE.RANGE.HIGH",
        rangeShortTextId: "MONITORING.DETAIL_DIALOG.JOHNS_HOPKINS_SCALE.RANGE.HIGH.SHORT",
        category: scales.HIGH,
        index: 1
      }
  }
}
