import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { isProduction } from ".";

export function initSentry() {

  const { 
    REACT_APP_TENANT,
    REACT_APP_VERSION,
    REACT_APP_API,
    REACT_APP_URL
  } = process.env
  
  const _REACT_APP_VERSION  = REACT_APP_VERSION ?? "Unknown"
  const _REACT_APP_TENANT   = REACT_APP_TENANT ?? "Unknown"
  const _REACT_APP_API      = REACT_APP_API ?? ""
  const _REACT_APP_URL      = REACT_APP_URL ?? ""

  const DNS = isProduction() 
    ? "https://9802d82d723a48b28aa26be63b876966@o4504239504818176.ingest.sentry.io/4504239506325504" 
    : "https://37f1fd18d0dc462d9c1374c31f5e1cdd@o4504239504818176.ingest.sentry.io/4504267325243392"
    
  
  if (!_REACT_APP_API.includes("192.168.1.199")) {
    Sentry.init({
      dsn: DNS,
            
      integrations: [new BrowserTracing()],
      environment: _REACT_APP_TENANT,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    })
    Sentry.setTags({
      stk_version : _REACT_APP_VERSION,
      stk_tenant  : _REACT_APP_TENANT,
      stk_api     : _REACT_APP_API,
      stk_url     : _REACT_APP_URL
    })
  }
}

export function logToSentry(err: any, message: string, extras: any = {}) {
  Sentry.captureException(err, {
    extra: {
      ...{
        message: message
      },
      ...extras
    }
  });
  console.error(err?.code ?? "Unknown Error Code", err)
}

export function setSentryUser(user: object) {
  Sentry.setUser(user)
}
