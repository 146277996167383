import React from "react";
import { ApexOptions } from "apexcharts";
import Chart from 'react-apexcharts'
import { IBradenEvolutionData } from "app/models/monitoring";

export interface PBradenEvolutionsDataProps {
  width?: number | string
  height?: number | string
  id?: string
  title: string
  sortTitle?: string
  colors: Array<string>
  labels: Array<string>
  sortLabels?: Array<string>
  data?: IBradenEvolutionData
}

function BradenEvolutionsPie(props: PBradenEvolutionsDataProps) {
  const { width, height, id, title, sortTitle, colors, labels, sortLabels, data } = props

  const [dataSerie, setDataSerie] = React.useState<Array<number>>([0,0,0])
  const [options, setOptions] = React.useState<ApexOptions>({
      chart: {
        id: id      
      },
      title: {
        text: title,
        align: 'left',
        style: {
          color: "white"
        }
      },
      noData: {
        style: {
          color: "red"
        },
        text: "Nothing"
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '12px',
                color: 'white'
              },
              value: {
                show: true,
                color: "white",      
              }
            }, 
          }
        },
      },
      stroke:{
        colors:['#0000000']
       },
      colors: colors,
      labels: labels,
      dataLabels: {
        formatter: (value, { seriesIndex, w }) => w.config.series[seriesIndex],
      },
      tooltip: { 
        enabled: false
      },
      legend: {       
        horizontalAlign: 'left',
        labels: {
          colors: "white"
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            title: {
              text: sortTitle !== undefined ? sortTitle : title,    
            }
          },     
        },
        {
          breakpoint: 780,
          options: {
            legend: {
              position: 'top',
              fontSize: '10vw',
            },
            labels: sortLabels,
          },     
        },
        {
          breakpoint: 781,
          options: {
            legend: {
              position: 'right',
              fontSize: '10vw',
            },
            labels: labels,
          },     
        },
        {
          breakpoint: 9999, 
          options: {
            legend: {
              position: 'right',
              fontSize: '10vw',
            },
            labels: labels,
          },
        },
      ]
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDataSerie = (): Array<number> => {
    if (!data) return [0, 0];

    return [data.totalHospitalizedPatients - (data.totalHospitalizedPatients - data.totalPatientsWithEvolutions), data.totalHospitalizedPatients - data.totalPatientsWithEvolutions];
  };

  React.useEffect(() => {
    const calculatedDataSerie = onDataSerie();

    setDataSerie(calculatedDataSerie);

    const percentageFormatter = (value: string): string => {
      const totalHospitalizedPatients = data?.totalHospitalizedPatients;
      if (totalHospitalizedPatients !== undefined && totalHospitalizedPatients !== 0) {
        const percentage = (Number(value) * 100) / totalHospitalizedPatients;
        return percentage.toFixed(2) + "%";
      } else {
        return "N/A";
      }
    };

    const options: ApexOptions = {
      chart: {
        id: id      
      },
      title: {
        text: title,
        align: 'left',
        style: {
          color: "white"
        }
      },
      noData: {
        style: {
          color: "red"
        },
        text: "Nothing"
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '12px',
                color: 'white'
              },
              value: {
                show: true,
                color: "white",
                formatter: percentageFormatter
                
              }
            },
          }
        }
      },
      stroke:{
        colors:['#0000000']
       },
      colors: colors,
      labels: labels,
      dataLabels: {
        formatter: (value: any, { seriesIndex, w }: any) => w.config.series[seriesIndex],
      },
      tooltip: { 
        enabled: false
      },
      legend: {
        horizontalAlign: 'left',
        labels: {
          colors: "white"
        }
      }
    }

    setOptions(options);

    setChartDimensions(getChartDimensions());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  React.useEffect(() => {
    const data = onDataSerie()
    if (!data) return

    setDataSerie(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [windowScreen, setWindowScreen] = React.useState(window.innerWidth)

  const getChartDimensions = () => {
    if (windowScreen < 480) {
      return { width: undefined, height: typeof height === "number" ? height + 50 : height};
    } else if (windowScreen < 780) {
      return { width: undefined, height: undefined };
    } else if (windowScreen < 1501) {
      return { width: width, height: height };
    } else if (windowScreen < 1780) {
      return { width: width, height: typeof height === "number" ? height - 30 : height };
    } else {
      return { width: width, height: height };
    }
  };
  
  const [chartDimensions, setChartDimensions] = React.useState(getChartDimensions());
  
  React.useEffect(() => {
    const handleResize = () => {
      setWindowScreen(window.innerWidth);
    };
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  React.useEffect(() => {
    setChartDimensions(getChartDimensions());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowScreen]);

  return ( 
    <Chart
      type="pie"
      options={options} 
      series={dataSerie} 
      width={chartDimensions.width} 
      height={chartDimensions.height} 
      />
  )
}
export default (BradenEvolutionsPie)
