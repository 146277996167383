import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Badge, Form } from "react-bootstrap";
import {
  getFixedClockDecubitusPositionForDate,
  getDecubitusPositionColor,
} from "app/models/position";
import "./decubitus-clock-table.scss";

// Props
export interface DynamicDecubitusClockTableProps {
  intl: any;
  startMonitoringDate: Date;
  hoursInterval?: number;
  rowsLimit?: number;
  showSelectInitialInterval?: boolean;
  highlightCurrentInterval?: boolean;
  currentIntervalFirst?: boolean;
  fullWidth?: boolean;
}

// Helper function to check if the time of date1 is after the time of date2, ignoring the day, month, and year
const isTimeAfter = (date1: Date, date2: Date): boolean => {
  const time1 = date1.getHours() * 3600 + date1.getMinutes() * 60 + date1.getSeconds() + date1.getMilliseconds() / 1000;
  const time2 = date2.getHours() * 3600 + date2.getMinutes() * 60 + date2.getSeconds() + date2.getMilliseconds() / 1000;
  return time1 > time2;
}

// Helper function to check if the time falls within the specified start and end times, ignoring the day, month, and year
const isTimeInRange = (time: Date, startTime: Date, endTime: Date): boolean => {
  return isTimeAfter(time, startTime) && !isTimeAfter(time, endTime);
}

// Helper function to generate the table rows
const generateRows = (
  startMonitoringDate: Date,
  hoursInterval: number,
  rowsLimit: number,
  currentIntervalFirst: boolean
) => {
  const rows = []; // Array to store the rows of the table
  let currentDate = new Date(startMonitoringDate); // Start from the initial date

  // Calculate the total number of rows in a 24-hour period
  const totalRows = Math.floor(24 / hoursInterval);

  // Loop to generate rows based on the limit
  for (let i = 0; i < totalRows; i++) {
    const startTime = new Date(currentDate);
    const startTimeString = startTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    currentDate.setHours(currentDate.getHours() + hoursInterval);
    const endTime = new Date(currentDate); // Capture the end time
    const endTimeString = endTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Add a row with the time interval and calculated position
    rows.push({
      interval: `${startTimeString} - ${endTimeString}`,
      startTime: startTime,
      endTime: endTime,
      position: getFixedClockDecubitusPositionForDate(
        startTime,
        hoursInterval
      ),
    });
  }

  // If "currentIntervalFirst" is true, we have to sort the rows so that they start with the current interval
  const now = new Date();
  if (currentIntervalFirst) {
    const currentIntervalIndex = rows.findIndex(
      (row) => isTimeInRange(now, row.startTime, row.endTime)
    );
    if (currentIntervalIndex >= 0) {
      rows.unshift(...rows.splice(currentIntervalIndex));
    }
  }

  // Limit the number of rows to the specified limit
  if (rowsLimit < rows.length) {
    rows.splice(rowsLimit);
  }

  return rows;
};

// Function to extract hours and minutes from a date as total minutes
const getTotalMinutes = (date: Date) =>
  date.getHours() * 60 + date.getMinutes();

// Function to check if a given time is within 2 dates, considering only the hours
// Example: time = 24/08/2021 10:00, startTime = 30/08/2021 09:00, endTime = 28/08/2021 11:00
// The function will return true because 10:00 is within the interval 09:00 - 11:00
const isTimeWithinHourInterval = (
  time: Date,
  startTime: Date,
  endTime: Date
) => {
  const startMinutes = getTotalMinutes(startTime);
  const endMinutes = getTotalMinutes(endTime);
  const currentMinutes = getTotalMinutes(time);

  if (startMinutes < endMinutes) {
    // Interval does not cross midnight
    return currentMinutes >= startMinutes && currentMinutes < endMinutes;
  } else {
    // Interval crosses midnight
    return currentMinutes >= startMinutes || currentMinutes < endMinutes;
  }
};

// Component
function DynamicDecubitusClockTable({
  intl,
  startMonitoringDate,
  hoursInterval = 2,
  rowsLimit = 99,
  showSelectInitialInterval = true,
  highlightCurrentInterval = true,
  currentIntervalFirst = false,
  fullWidth = false,
}: DynamicDecubitusClockTableProps) {
  const initialRows = generateRows(
    startMonitoringDate,
    hoursInterval,
    rowsLimit,
    currentIntervalFirst
  );
  const [initialRowIndex, setInitialRowIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleInitialIntervalChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setInitialRowIndex(Number(event.target.value));
  };

  const rows = [
    ...initialRows.slice(initialRowIndex),
    ...initialRows.slice(0, initialRowIndex),
  ];

  return (
    <div style={{ width: fullWidth ? "100%" : "auto" }}>
      {showSelectInitialInterval && (
        <Form.Group
          controlId="initialInterval"
          className="select-interval-form-group"
        >
          <Form.Label>
            <FormattedMessage id="PATIENT.MONITORING.DECUBITUS_CLOCK_TABLE.SELECT_INITIAL_INTERVAL" />
          </Form.Label>
          <Form.Control
            as="select"
            value={initialRowIndex}
            onChange={handleInitialIntervalChange}
          >
            {initialRows.map((row, index) => (
              <option key={index} value={index}>
                {row.interval}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}
      <table className="decubitus-table table table-row-dashed table-row-gray-300 gy-7">
        <thead>
          <tr className="fw-bolder fs-6 text-gray-800">
            <th>
              <FormattedMessage id="PATIENT.MONITORING.DECUBITUS_CLOCK_TABLE.HEADER.INTERVAL" />
            </th>
            <th>
              <FormattedMessage id="PATIENT.MONITORING.DECUBITUS_CLOCK_TABLE.HEADER.POSITION" />
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td
                className={
                  highlightCurrentInterval &&
                    isTimeWithinHourInterval(
                      currentTime,
                      row.startTime,
                      row.endTime
                    )
                    ? "current-interval-row"
                    : ""
                }
              >
                {row.interval}
              </td>
              <td
                className={
                  highlightCurrentInterval &&
                    isTimeWithinHourInterval(
                      currentTime,
                      row.startTime,
                      row.endTime
                    )
                    ? "current-position-row"
                    : ""
                }
              >
                <Badge
                  className="decubitus-badge"
                  style={{
                    backgroundColor: getDecubitusPositionColor(row.position),
                  }}
                >
                  {row.position}
                </Badge>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default injectIntl(DynamicDecubitusClockTable);
